@import '../../styles/vars';

.code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: $silver;
  border-radius: 6px;
  color: $secondary-color;

  &.inverted {
    color: $inverted-primary-color;
  }
}