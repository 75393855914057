@import '../../styles/vars';

.anchor {
  color: $blue;

  &:not(.button) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}