@import '../../styles/vars';

.contact-form {
  max-width: 100%;
  width: 435px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .button {
    margin: 0.5em 0 0 0;
  }
}

.contact-buttons {
  display: flex;

  .button {
    width: 100%;
    margin: 0;
  }
}