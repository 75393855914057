@import '../../styles/vars';
@import '../../styles/mixins';

.input-container {
  @include input-container-styles;

  &.has-error {
    @include error-container-styles;

    .error {
      @include error-styles;
    }
  }

  .input {
    @include input-styles;
  }
}
