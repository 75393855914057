@import '../../styles/vars';

.heading-buttons {
  padding: 25px 0 0;
  text-align: center;

  .button {
    @media screen and (max-width:$ipad-size) {
      width: 100%;
      margin: 0 0 0.5rem 0;
      justify-content: center;
    }
  }
}

.ring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 3rem 0;

  @media screen and (max-width:$ipad-size) {
    align-items: flex-start;
    padding: 0;
  }
}

.heading {
  @media screen and (max-width:$ipad-size) {
    font-size: 32px !important;
  }
}

.hero-heading {
  max-width: 650px;

  @media screen and (max-width:$ipad-size) {
    max-width: 100%;
    text-align: left !important;
    font-size: 40px !important;
  }
}

.icon-button {
  color: inherit !important;
  margin: 0 0.4rem 0 0;
  display: inline-block;
}

.color-red {
  color: $red;
}

.color-grey {
  color: $grey;
}

.icons {
  > svg {
    margin: 0 0.5rem 0 0;
  }
}
