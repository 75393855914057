.footer {
  .text {
    text-align: center;
    margin: 0;
    font-size: 1rem;
  }

  .footer-icons {
    text-align: center;
    padding-bottom: 1rem;
  }

  .icon-button {
    display: inline-block;

    &:last-child {
      margin: 0;
    }
  }
}