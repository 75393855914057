@import "../../styles/vars";

.text {
  color: $text-color;
  margin: 0 0 1em;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0;

  &.inverted {
    color: $inverted-primary-color;
  }
}