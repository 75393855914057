@import '../../styles/vars';

.button {
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  font-size: 1rem;
  letter-spacing: 0;
  cursor: pointer;
  transition: 0.2s all;
  padding: 10px 20px;
  border-radius: 7px;
  margin: 0 0.4em;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;

  &:focus {
    outline: $blue solid 1px;
    outline-offset: 3px;
  }

  &.default {
    background: $inverted-primary-color;
    color: $primary-color;

    &:hover {
      background-color: lighten($inverted-primary-color, 5%);
    }
  }

  &.inverted {
    background: $primary-color;
    color: $inverted-primary-color;

    &:hover {
      background-color: lighten($primary-color, 5%);
    }
  }

  &.primary {
    background: $green;
    color: $primary-color;

    &:hover {
      background-color: lighten($green, 5%);
    }
  }

  &.secondary {
    background: $silver;
    color: $primary-color;

    &:hover {
      background-color: lighten($silver, 5%);
    }
  }

  &.negative {
    background: $red;
    color: $inverted-primary-color;

    &:hover {
      background-color: lighten($red, 5%);
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: darken($inverted-primary-color, 10%);
    color: lighten($primary-color, 10%);

    &:hover {
      background-color: darken($inverted-primary-color, 15%);
    }
  }

  &.large {
    height: 50px;
    line-height: 45px;
    font-size: 1.25rem;
  }

  &.normal {
    height: 40px;
    line-height: 35px;
    font-size: 1rem;
  }

  &.small {
    height: 35px;
    line-height: 30px;
    font-size: 0.8rem;
  }

  &.tiny {
    height: 25px;
    line-height: 20px;
    font-size: 0.6rem;
  }

  .loading-icon {
    font-size: 1.5rem;
    animation: loading 1s linear infinite;
  }
}


@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}