$green: #64B6AC;
$blue: #2B2D42;
$red: #D90429;
$grey: #EDF2F4;
$silver: #8D99AE;

$primary-color: #000;
$secondary-color: $grey;
$inverted-primary-color: #FFF;

$heading-color: $blue;
$text-color: $primary-color;

$ipad-size: 768px;