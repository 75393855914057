@import '../../styles/vars';

.heading {
  border: none;
  margin: 0 0 0.5em 0;
  padding: 0 0;
  font-family: 'Unbounded', Lato, Arial, Helvetica, cursive;
  font-weight: 700;
  line-height: 1.2;
  text-transform: none;
  color: $heading-color;

  &.inverted {
    color: $inverted-primary-color;
  }

  &.align-center {
    text-align: center;
  }
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}