@import "../../styles/vars";

.not-found {
  .heading {
    margin: 0;
    font-size: 80px !important;
  }

  .text {
    margin-top: -0.9rem;
    font-size: 24px;
    color: lighten($grey, 10%);
  }

  .button {
    margin: 1.5em 0 0 0;
  }

  &.segment {
    height: 100%;
  }
}
