@import 'vars';

@mixin input-styles {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba($inverted-primary-color, 0);
  text-align: left;
  font-size: 1rem;
  line-height: 1.21428571em;
  padding: 0.581em 1em;
  background: $inverted-primary-color;
  border: 1px solid rgba($grey,.15);
  color: rgba($primary-color,.9);
  border-radius: 0.28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;

  .error {
    display: none;
  }

  &::placeholder {
    text-overflow: inherit;
    overflow-wrap: normal;
    line-height: initial !important;
    -webkit-user-modify: read-only !important;
    overflow: hidden;
    color: rgba($primary-color,.3);
  }

  &:active {
    border-color: rgba($primary-color,.3);
    background: lighten($inverted-primary-color, 10%);
    color: rgba($primary-color,.87);
    box-shadow: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 0s 5s, color 0s 5s;
    box-shadow: 0 0 0 1000px lighten($inverted-primary-color, 10%) inset;
  }

  &:focus {
    border-color: darken($secondary-color, 10%);
    background: $inverted-primary-color;
    color: rgba($primary-color,.8);
    box-shadow: none;

    &::placeholder {
      color: rgba($primary-color,.5);
    }
  }
}

@mixin input-container-styles {
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  color: rgba($primary-color, .9);
  margin: 0.5em 0;
}

@mixin error-container-styles {
  position: relative;

  >.input,
  >.textarea {
    border-color: $red;

    &:focus,
    &:active {
      border-color: $red;
    }
  }
}

@mixin error-styles {
  display: block;
  position: absolute;
  font-size: 0.55em;
  padding: 0;
  margin: 0;
  bottom: -1.5em;
  color: $red;
}