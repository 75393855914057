@import '../../styles/vars';

.col {
  width: 50%;

  @media screen and (max-width:$ipad-size) {
    width: 100% !important;
    padding: 0 !important;
  }

  &.lg {
    width: 75%;
  }

  &.sm {
    width: 25%;
  }

  &.left {
    padding-right: 1rem;
  }

  &.right {
    padding-left: 1rem;
  }
}