@import 'vars';

html,
body {
  font-size: 16px;
  line-height: 1.15;
  font-family: 'Spline Sans Mono', Lato, Arial, Helvetica, monospace;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $secondary-color;
}

#root {
  height: 100%;
}

.text-align-center {
  text-align: center;
}
