@import '../../styles/vars';

.ring {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  border: 30px solid $silver;
  border-radius: 50%;

  @media screen and (max-width:$ipad-size) {
    transform: scale(0.5) translateX(-50%);
  }

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border: 5px solid $inverted-primary-color;
    border-radius: 50%;
    top: 2px;
    left: -5px;
  }

  .inner-ring {
    position: absolute;
    border-radius: 50%;
    top: -23px;
    left: -30px;
    width: 100px;
    height: 100px;
    display: flex;
    border: 5px solid $inverted-primary-color;
  }
}