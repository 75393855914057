@import '../../styles/vars';

.segment {
  padding: 100px 25px;
  display: flex;
  flex-direction: column;

  &.small-padding {
    padding: 35px;
  }

  @media screen and (max-width:$ipad-size) {
    padding: 35px 25px;
  }

  &.centered {
    justify-content: center;
    align-items: center;
  }

  &.primary {
    background-color: $blue;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.hero {
    border-bottom: 1px solid rgba($grey, 0.35);
  }
}