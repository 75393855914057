.container {
  position: relative;
  max-width: 1000px !important;
  left: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &.full-height {
    height: 100%;
  }

  &.centered {
    justify-content: center;
    align-items: center;
  }

  &.full-width {
    max-width: 100% !important;
  }
}